<template>
    <div>

        <!-- Form: Info Form -->
        <validation-observer ref="simpleRules">
            <b-form>
                <b-row>

                    <!-- Generate Coupon Code Button -->
                    <b-col md="12">
                        <b-button
                            @click="generateDicountCouponCode"
                            size="sm"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                        >
                            GENERAR UN CÓDIGO DE CUPÓN
                        </b-button>
                        <hr>
                    </b-col>

                    <!-- Code -->
                    <b-col md="4">
                        <b-form-group
                            label="Código"
                            label-for="code">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="código de cupón"
                            >
                                <b-form-input
                                    id="code"
                                    v-model="couponData.code"
                                    placeholder="Código de cupón ..."
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Coupon Type -->
                    <b-col md="4">
                        <b-form-group
                            label="Seleccionar tipo de descuento"
                            label-for="coupon_type">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="tipo de descuento"
                            >
                                <v-select
                                    id="coupon_type"
                                    v-model="couponData.coupon_type"
                                    :state="errors.length > 0 ? false : null"
                                    :options="types"
                                    label="name"
                                />

                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Amount -->
                    <b-col md="4">
                        <b-form-group
                            label="Importe (S/ o %)"
                            label-for="amount">
                            <validation-provider
                                #default="{ errors }"
                                rules="required"
                                name="importe de cupón"
                            >
                                <b-form-input
                                    id="amount"
                                    v-model="couponData.discount_number"
                                    placeholder="importe del cupón ..."
                                    :state="errors.length > 0 ? false : null"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Expiration Date -->
                    <b-col md="4">
                        <b-form-group
                            label="Fecha de caducidad"
                            label-for="expiration_date" class="mb-0">

                            <flat-pickr
                                id="expiration_date"
                                v-model="couponData.expiration_date"
                                class="form-control"
                                :config="config"
                                placeholder="YYYY-MM-DD"
                            />

                        </b-form-group>
                    </b-col>

                </b-row>
            </b-form>
        </validation-observer>
    
        <!-- Action Buttons -->
        <b-button
            @click="updateInformation"
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
            ACTUALIZAR
        </b-button>
    </div>
</template>

<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { ValidationProvider, ValidationObserver } from 'vee-validate';
    import { useToast } from 'vue-toastification/composition';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import flatPickr from 'vue-flatpickr-component';
    import { ref } from '@vue/composition-api';
    import Ripple from 'vue-ripple-directive';
    import { required } from '@validations';
    import vSelect from 'vue-select';
    import cc  from 'coupon-code';
    import store from '@/store';
    import axios from '@axios';

    export default {
        components: {
            ValidationProvider,
            ValidationObserver,

            flatPickr,
            vSelect,

            required
        },
        props: {
            couponData: {
                type: Object,
                required: true
            }
        },
        directives: {
            Ripple
        },
        data (){
            return {
                types: [],

                config: {
                    enableTime: false,
                    locale: Spanish
                }
            }
        },
        async beforeMount (){
            await this.getCouponTypes();
        },
        methods: {
            async getCouponTypes (){
                let response = await axios.get('/coupon-types/select');
                this.types = response.data;
            },
            generateDicountCouponCode (){
                this.couponData.code = cc.generate(); 
            }
        },
        setup(props) {

            // Use Toast
            const toast = useToast();

            const simpleRules = ref(null);

            const updateInformation = () => {
                simpleRules.value.validate().then(success => {
                    if (success) {

                        let data = {
                            code: props.couponData.code,
                            coupon_type_id: props.couponData.coupon_type.id,
                            discount_number: props.couponData.discount_number,
                            expiration_date: props.couponData.expiration_date
                        };

                        let couponData = {
                            ...data
                        };

                        store.dispatch('app-coupon/updateCoupon', { id: props.couponData.id, couponData})
                            .then( (response) => {
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: response.data.message,
                                        icon: 'CheckIcon',
                                        variant: 'success'
                                    }
                                });
                            })
                            .catch( (err) => {
                                let message = err.response.data.message ? err.response.data.message : 'Error al actualizar la información del cupón de descuento.';
                                toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: message,
                                        icon: 'AlertTriangleIcon',
                                        variant: 'danger'
                                    }
                                });
                            });
                    }
                });
            };

            return {
                updateInformation,
                simpleRules
            }
        }
    }

</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>